import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import '../styles/SharedAnimations.css';
import comServ1 from '../images/comServ1.jpg';
import comServ2 from '../images/comServ2.jpg';
import comServ3 from '../images/comServ3.jpg';
import comServ4 from '../images/comServ4.jpg';
import metLead1 from '../images/metLead1.jpg';
import metLead2 from '../images/metLead2.jpg';
import metLead3 from '../images/metLead3.jpg';
import metLead4 from '../images/metLead4.jpg';
import metLead5 from '../images/metLead5.jpg';
import metLead6 from '../images/metLead6.jpg';
import metLead7 from '../images/metLead7.jpg';
import metLead8 from '../images/metLead8.jpg';
import culEvt1 from '../images/culEvt1.jpg';
import culEvt2 from '../images/culEvt2.jpg';
import culEvt3 from '../images/culEvt3.jpg';

import img3 from '../images/img3.jpg';
import img20 from '../images/img20.jpg';


import img1  from '../images/img1.jpg';
import img2  from '../images/img2.jpg';

// Image folder data structure
const imageFolders = {
  'community-service': {
    title: 'Community Service',
    description: 'Making a difference in our community through various service initiatives.',
    thumbnail: comServ4,
    images: [
      {
        id: 1,
        url: comServ1,
        title: 'Food Distribution Drive',
        date: '2024-01-20',
        description: 'Distribution of essential supplies to underprivileged families'
      },
      {
        id: 2,
        url: comServ2,
        title: 'Medical Camp',
        date: '2024-01-15',
        description: 'Free health checkup camp for the community'
      },
      {
        id: 3,
        url: comServ3,
        title: 'Education Initiative',
        date: '2024-01-10',
        description: 'Supporting local schools with resources'
      },
      {
        id: 4,
        url: comServ4,
        title: 'Education Initiative',
        date: '2024-01-10',
        description: 'Supporting local schools with resources'
      }
    ]
  },
  'meetings with leaders': {
    title: 'Public Meetings',
    description: 'Engaging with citizens and addressing community concerns.',
    thumbnail: metLead4,
    images: [
      {
        id: 5,
        url: metLead1,
        title: 'Town Hall Meeting',
        date: '2024-01-05',
        description: 'Interactive session with local residents'
      },
      {
        id: 6,
        url: metLead2,
        title: 'Youth Conference',
        date: '2023-12-28',
        description: 'Discussing opportunities with young leaders'
      },
      {
        id: 7,
        url: metLead3,
        title: 'Youth Conference',
        date: '2023-12-28',
        description: 'Discussing opportunities with young leaders'
      },
      {
        id: 8,
        url: metLead4,
        title: 'Youth Conference',
        date: '2023-12-28',
        description: 'Discussing opportunities with young leaders'
      },
      {
        id: 14,
        url: metLead5,
        title: 'Youth Conference',
        date: '2023-12-28',
        description: 'Discussing opportunities with young leaders'
      },
      {
        id: 15,
        url: metLead6,
        title: 'Youth Conference',
        date: '2023-12-28',
        description: 'Discussing opportunities with young leaders'
      },
      {
        id: 16,
        url: metLead7,
        title: 'Youth Conference',
        date: '2023-12-28',
        description: 'Discussing opportunities with young leaders'
      },
      {
        id: 17,
        url: metLead8,
        title: 'Youth Conference',
        date: '2023-12-28',
        description: 'Discussing opportunities with young leaders'
      }
    ]
  },
  'cultural-events': {
    title: 'Cultural Events',
    description: 'Celebrating our rich cultural heritage and traditions.',
    thumbnail: culEvt1,
    images: [
      {
        id: 9,
        url: culEvt1,
        title: 'Festival Celebrations',
        date: '2023-12-20',
        description: 'Annual cultural festival'
      },
      {
        id: 10,
        url: culEvt2,
        title: 'Traditional Dance',
        date: '2023-12-15',
        description: 'Showcase of local dance forms'
      },
      {
        id: 11,
        url: culEvt3,
        title: 'Traditional Dance',
        date: '2023-12-15',
        description: 'Showcase of local dance forms'
      }
    ]
  },
  'development-projects': {
    title: 'Development Projects',
    description: 'Infrastructure and development initiatives for our region.',
    thumbnail: img3,
    images: [
      {
        id: 12,
        url: img3,
        title: 'Road Construction',
        date: '2023-12-10',
        description: 'New road development project'
      },
      {
        id:13 ,
        url: img20,
        title: 'School Building',
        date: '2023-12-05',
        description: 'New educational facility inauguration'
      }
    ]
  },
  'youth-programs': {
    title: 'Youth Programs',
    description: 'Empowering the next generation through various initiatives.',
    thumbnail: img20,
    images: [
      {
        id: 10,
        url: img20,
        title: 'Youth Leadership Program',
        date: '2023-11-30',
        description: 'Training future leaders'
      },
      {
        id: 11,
        url: img1,
        title: 'Sports Tournament',
        date: '2023-11-25',
        description: 'Annual youth sports meet'
      }
    ]
  },
  'press-coverage': {
    title: 'Press Coverage',
    description: 'Media coverage and press interactions.',
    thumbnail: img2,
    images: [
      {
        id: 12,
        url: img2,
        title: 'Press Conference',
        date: '2023-11-20',
        description: 'Addressing the media'
      },
      {
        id: 13,
        url: img3,
        title: 'News Interview',
        date: '2023-11-15',
        description: 'Discussion on local development'
      }
    ]
  }
};

const ImageGallery = () => {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [visibleImages, setVisibleImages] = useState(6);

  const ImageModal = ({ image, onClose }) => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      padding: '2rem'
    }}>
      <div className="gallery-modal" style={{
        maxWidth: '90%',
        maxHeight: '90vh',
        position: 'relative'
      }}>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '-2rem',
            top: '-2rem',
            background: 'none',
            border: 'none',
            color: 'white',
            fontSize: '2rem',
            cursor: 'pointer',
            zIndex: 1001,
            transition: 'transform 0.3s ease'
          }}
          onMouseEnter={e => e.target.style.transform = 'rotate(90deg)'}
          onMouseLeave={e => e.target.style.transform = 'rotate(0deg)'}
        >
          ×
        </button>
        <img
          src={image.url}
          alt={image.title}
          style={{
            maxWidth: '100%',
            maxHeight: '80vh',
            objectFit: 'contain',
            borderRadius: '8px'
          }}
        />
        <div style={{
          color: 'white',
          marginTop: '1rem',
          textAlign: 'center'
        }}>
          <h3 style={{ marginBottom: '0.5rem' }}>{image.title}</h3>
          <p>{image.description}</p>
          <p style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: 'rgba(255, 255, 255, 0.8)' }}>
            {new Date(image.date).toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );

  // If we're in a specific folder
  if (folderId && imageFolders[folderId]) {
    const folder = imageFolders[folderId];
    return (
      <div className="page-container">
        <div style={{ marginBottom: '2rem' }}>
          <Link 
            to="/image-gallery" 
            style={{ 
              color: 'var(--primary-blue)', 
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}
          >
            ← Back to Folders
          </Link>
        </div>
        <h1 className="page-title">{folder.title}</h1>
        <p style={{ textAlign: 'center', marginBottom: '2rem' }}>{folder.description}</p>

        <section className="section">
          <div style={{
            columnCount: 3,
            columnGap: '1.5rem',
            padding: '1.5rem',
            '@media (max-width: 1024px)': {
              columnCount: 2
            },
            '@media (max-width: 640px)': {
              columnCount: 1
            }
          }}>
            {folder.images.slice(0, visibleImages).map(image => (
              <div
                key={image.id}
                style={{
                  breakInside: 'avoid',
                  marginBottom: '1.5rem',
                  cursor: 'pointer'
                }}
                onClick={() => setSelectedImage(image)}
              >
                <div className="gallery-card">
                  <img
                    src={image.url}
                    alt={image.title}
                    style={{
                      width: '100%',
                      display: 'block',
                      borderRadius: '8px 8px 0 0',
                      aspectRatio: '16/9',
                      objectFit: 'cover'
                    }}
                  />
                  <div className="content" style={{
                    padding: '1rem',
                    backgroundColor: 'white',
                    borderRadius: '0 0 8px 8px'
                  }}>
                    <h3 style={{
                      margin: '0 0 0.5rem 0',
                      fontSize: '1.1rem',
                      color: 'var(--text-primary)'
                    }}>{image.title}</h3>
                    <p style={{ 
                      fontSize: '0.9rem', 
                      color: 'var(--text-secondary)',
                      margin: '0'
                    }}>
                      {new Date(image.date).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {folder.images.length > visibleImages && (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem'
            }}>
              <button
                onClick={() => setVisibleImages(prev => prev + 6)}
                style={{
                  padding: '0.75rem 2rem',
                  backgroundColor: 'var(--primary-blue)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem'
                }}
              >
                Load More
              </button>
            </div>
          )}
        </section>

        {selectedImage && (
          <ImageModal
            image={selectedImage}
            onClose={() => setSelectedImage(null)}
          />
        )}
      </div>
    );
  }

  // Main folder view
  return (
    <div className="page-container">
      <h1 className="page-title">Image Gallery</h1>

      <section className="section">
        <div className="video-grid" style={{ 
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '2rem',
          padding: '1.5rem'
        }}>
          {Object.entries(imageFolders).map(([folderId, folder]) => (
            <div
              key={folderId}
              className="gallery-card"
              onClick={() => navigate(`/image-gallery/${folderId}`)}
              style={{ cursor: 'pointer' }}
            >
              <div style={{ position: 'relative' }}>
                <img
                  src={folder.thumbnail}
                  alt={folder.title}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px 8px 0 0'
                  }}
                />
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'linear-gradient(to bottom, rgba(1, 42, 74, 0.2), rgba(1, 42, 74, 0.8))',
                  borderRadius: '8px 8px 0 0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.5rem',
                  color: 'white',
                  fontWeight: '500'
                }}>
                  View Gallery
                </div>
              </div>
              <div style={{
                padding: '1.5rem',
                backgroundColor: 'white',
                borderRadius: '0 0 8px 8px'
              }}>
                <h3 style={{
                  margin: '0 0 0.5rem 0',
                  fontSize: '1.3rem',
                  color: 'var(--primary-blue)'
                }}>{folder.title}</h3>
                <p style={{
                  fontSize: '0.9rem',
                  color: 'var(--dark-gray)',
                  margin: '0'
                }}>{folder.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ImageGallery; 