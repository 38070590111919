import React from 'react';
import Impact from './Impact';
import Journey from './Journey';
import VideoGallery from './VideoGallery';
import ImageGallery from './ImageGallery';
import Press from './Press';
import Event from './Event';
import SocialMedia from './SocialMedia';
import Contact from './Contact';

const Home = () => {
  return (
    <div>
      {/* Page Sections */}
      <div id="impact">
        <Impact />
      </div>

      <div id="journey" style={{ background: 'var(--light-gray)' }}>
        <Journey />
      </div>

      <div id="video-gallery">
        <VideoGallery />
      </div>

      <div id="image-gallery" style={{ background: 'var(--light-gray)' }}>
        <ImageGallery />
      </div>

      <div id="press">
        <Press />
      </div>

      <div id="event" style={{ background: 'var(--light-gray)' }}>
        <Event />
      </div>

      <div id="social-media">
        <SocialMedia />
      </div>

      <div id="contact" style={{ background: 'var(--light-gray)' }}>
        <Contact />
      </div>

      {/* Floating Navigation */}
      <div style={{
        position: 'fixed',
        right: '2rem',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        zIndex: 1000
      }}>
        {[
          { id: 'impact', label: 'Impact' },
          { id: 'journey', label: 'Journey' },
          { id: 'video-gallery', label: 'Videos' },
          { id: 'image-gallery', label: 'Images' },
          { id: 'press', label: 'Press' },
          { id: 'event', label: 'Events' },
          { id: 'social-media', label: 'Social' },
          { id: 'contact', label: 'Contact' }
        ].map(({ id, label }) => (
          <a
            key={id}
            href={`#${id}`}
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: 'var(--primary-orange)',
              position: 'relative',
              transition: 'all 0.3s ease',
              ':hover': {
                transform: 'scale(1.2)'
              }
            }}
          >
            <span style={{
              position: 'absolute',
              right: '100%',
              top: '50%',
              transform: 'translateY(-50%)',
              marginRight: '1rem',
              color: 'var(--primary-orange)',
              fontSize: '0.875rem',
              fontWeight: '500',
              opacity: 0,
              transition: 'opacity 0.3s ease',
              whiteSpace: 'nowrap',
              pointerEvents: 'none'
            }}>
              {label}
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Home; 