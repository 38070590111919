import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={{
      position: 'fixed',
      top: '80px',
      right: '20px',
      zIndex: 1000,
      display: 'flex',
      gap: '10px',
      background: 'var(--primary-blue)',
      padding: '8px 12px',
      borderRadius: '20px',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
      animation: 'fadeIn 0.3s ease-out'
    }}>
      <button
        onClick={() => changeLanguage('en')}
        style={{
          background: i18n.language === 'en' ? 'white' : 'transparent',
          color: i18n.language === 'en' ? 'var(--primary-blue)' : 'white',
          border: '1px solid white',
          padding: '6px 15px',
          borderRadius: '15px',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: '500',
          transition: 'all 0.3s ease',
          minWidth: '45px'
        }}
      >
        EN
      </button>
      <button
        onClick={() => changeLanguage('hi')}
        style={{
          background: i18n.language === 'hi' ? 'white' : 'transparent',
          color: i18n.language === 'hi' ? 'var(--primary-blue)' : 'white',
          border: '1px solid white',
          padding: '6px 15px',
          borderRadius: '15px',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: '500',
          transition: 'all 0.3s ease',
          minWidth: '45px'
        }}
      >
        हिंदी
      </button>
    </div>
  );
};

export default LanguageSwitcher; 