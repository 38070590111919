import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    district: '',
    phone: '',
    ward: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setSubmitted(true);
    setFormData({
      name: '',
      district: '',
      phone: '',
      ward: '',
      message: ''
    });
  };

  const offices = [
    {
      id: 1,
      name: 'Main Office',
      address: 'Parshuram Colony, Sandalpur more, Kumhrar, Patna 800007',
      phone: '7033168666',
      email: 'pankajsinha@ashwanikumar.org',
      hours: 'Monday - Friday: 9:00 AM - 5:00 PM'
    },
    {
      id: 2,
      name: 'Constituency Office',
      address: 'Professor colony, Bajrangpuri, Ward No. 54, 800007',
      phone: '9693085416',
      email: 'pankajsinha@ashwanikumar.org',
      hours: 'Monday - Saturday: 10:00 AM - 6:00 PM'
    },
   
  ];

  const inputStyle = {
    width: '100%',
    padding: '1rem',
    marginBottom: '1.5rem',
    border: '1px solid rgba(1, 42, 74, 0.1)',
    borderRadius: '8px',
    fontSize: '1rem',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    transition: 'all 0.3s ease'
  };

  const officeCardStyle = (id) => ({
    background: hoveredCard === id ? 'linear-gradient(135deg, var(--primary-blue), var(--secondary-blue))' : 'white',
    padding: '2rem',
    borderRadius: '15px',
    boxShadow: hoveredCard === id 
      ? '0 15px 40px rgba(1, 42, 74, 0.15)'
      : '0 10px 30px rgba(1, 42, 74, 0.1)',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    border: '1px solid rgba(1, 42, 74, 0.1)',
    transform: hoveredCard === id ? 'translateY(-5px)' : 'translateY(0)',
    color: hoveredCard === id ? 'white' : 'inherit'
  });

  const officeHeadingStyle = (id) => ({
    color: hoveredCard === id ? 'white' : 'var(--primary-blue)',
    marginBottom: '1.5rem',
    fontSize: '1.5rem',
    borderBottom: hoveredCard === id 
      ? '1px solid rgba(255, 255, 255, 0.2)'
      : '1px solid rgba(1, 42, 74, 0.1)',
    paddingBottom: '0.75rem',
    transition: 'all 0.3s ease'
  });

  const officeLabelStyle = (id) => ({
    color: hoveredCard === id ? 'rgba(255, 255, 255, 0.8)' : 'inherit',
    transition: 'all 0.3s ease'
  });

  return (
    <div className="page-container" style={{ maxWidth: '100%', padding: '0' }}>
      <div style={{
        background: 'linear-gradient(135deg, rgba(1, 42, 74, 0.05), rgba(1, 42, 74, 0.1))',
        padding: '4rem 2rem'
      }}>
        <h1 className="page-title">Contact Us</h1>

        <div style={{
          maxWidth: '1400px',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr 2px 1fr',
          gap: '4rem',
          position: 'relative'
        }}>
          {/* Left Section - Contact Form */}
          <div style={{
            background: 'white',
            padding: '2.5rem',
            borderRadius: '20px',
            boxShadow: '0 10px 30px rgba(1, 42, 74, 0.1)'
          }}>
            <h2 style={{ 
              color: 'var(--primary-blue)', 
              marginBottom: '2rem', 
              fontSize: '2rem',
              borderBottom: '2px solid rgba(1, 42, 74, 0.1)',
              paddingBottom: '1rem'
            }}>
              Send us a Message
            </h2>

            {submitted ? (
              <div style={{
                textAlign: 'center',
                padding: '3rem',
                background: 'linear-gradient(135deg, rgba(1, 42, 74, 0.02), rgba(1, 42, 74, 0.05))',
                borderRadius: '12px'
              }}>
                <h3 style={{ color: 'var(--primary-blue)', marginBottom: '1.5rem', fontSize: '1.5rem' }}>Thank You!</h3>
                <p style={{ marginBottom: '2rem', fontSize: '1.1rem' }}>Your message has been received. We will get back to you shortly.</p>
                <button
                  onClick={() => setSubmitted(false)}
                  style={{
                    backgroundColor: 'var(--primary-blue)',
                    color: 'white',
                    border: 'none',
                    padding: '1rem 2rem',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '1.1rem',
                    transition: 'all 0.3s ease'
                  }}
                >
                  Send Another Message
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem', marginBottom: '1.5rem' }}>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                    style={inputStyle}
                  />
                  <input
                    type="text"
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    placeholder="District"
                    
                    style={inputStyle}
                  />
                </div>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem', marginBottom: '1.5rem' }}>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    style={inputStyle}
                  />
                  <input
                    type="text"
                    name="Ward"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Ward"
                    required
                    style={inputStyle}
                  />
                </div>

                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  required
                  style={{
                    ...inputStyle,
                    height: '200px',
                    resize: 'vertical',
                    marginBottom: '2rem'
                  }}
                />

                <button
                  type="submit"
                  style={{
                    backgroundColor: 'var(--primary-blue)',
                    color: 'white',
                    border: 'none',
                    padding: '1.25rem 2.5rem',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    width: '100%',
                    fontSize: '1.1rem',
                    fontWeight: '500',
                    transition: 'all 0.3s ease',
                    boxShadow: '0 4px 15px rgba(1, 42, 74, 0.2)'
                  }}
                >
                  Send Message
                </button>
              </form>
            )}
          </div>

          {/* Divider */}
          <div style={{
            background: 'linear-gradient(to bottom, transparent, rgba(1, 42, 74, 0.1), transparent)',
            width: '2px',
            margin: '2rem 0'
          }}></div>

          {/* Right Section - Office Information */}
          <div>
            <h2 style={{ 
              color: 'var(--primary-blue)', 
              marginBottom: '2rem', 
              fontSize: '2rem',
              borderBottom: '2px solid rgba(1, 42, 74, 0.1)',
              paddingBottom: '1rem'
            }}>
              Our Offices
            </h2>
            
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem'
            }}>
              {offices.map(office => (
                <div
                  key={office.id}
                  className="office-card"
                >
                  <h3>{office.name}</h3>
                  <div style={{ display: 'grid', gap: '1rem', fontSize: '1.1rem' }}>
                    <p><strong>📍 Address:</strong> {office.address}</p>
                    <p><strong>📞 Phone:</strong> {office.phone}</p>
                    <p><strong>✉️ Email:</strong> {office.email}</p>
                    <p><strong>🕒 Hours:</strong> {office.hours}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact; 