import React, { useState } from 'react';
import { HashRouter, Routes, Route, NavLink } from 'react-router-dom';
import './App.css';
import './i18n';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';

// Page components
import Home from './pages/Home';
import Impact from './pages/Impact';
import Journey from './pages/Journey';
import VideoGallery from './pages/VideoGallery';
import ImageGallery from './pages/ImageGallery';
import Press from './pages/Press';
import Event from './pages/Event';
import SocialMedia from './pages/SocialMedia';
import Contact from './pages/Contact';
import StickyNav from './components/StickyNav';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <LanguageSwitcher />
      <HashRouter>
        <div className="App">
          <nav className="navbar">
            <div className="logo">
              <NavLink to="/" onClick={closeMenu}>Ashwani Kumar</NavLink>
            </div>
            <div className="nav-links">
              <NavLink to="/" onClick={closeMenu}>{t('home')}</NavLink>
              <NavLink to="/impact" onClick={closeMenu}>{t('impact')}</NavLink>
              <NavLink to="/journey" onClick={closeMenu}>{t('journey')}</NavLink>
              <NavLink to="/contact" onClick={closeMenu}>{t('contact')}</NavLink>
            </div>
            <button 
              className={`menu-button ${isMenuOpen ? 'active' : ''}`} 
              onClick={toggleMenu}
              aria-label="Toggle navigation menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div className={`menu-overlay ${isMenuOpen ? 'active' : ''}`} onClick={closeMenu}></div>
            <div className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`}>
              <NavLink to="/" onClick={closeMenu}>{t('home')}</NavLink>
              <NavLink to="/impact" onClick={closeMenu}>{t('impact')}</NavLink>
              <NavLink to="/journey" onClick={closeMenu}>{t('journey')}</NavLink>
              <NavLink to="/contact" onClick={closeMenu}>{t('contact')}</NavLink>
              <div className="menu-divider"></div>
              <NavLink to="/video-gallery" onClick={closeMenu}>{t('gallery')}</NavLink>
              <NavLink to="/image-gallery" onClick={closeMenu}>{t('gallery')}</NavLink>
              <NavLink to="/press" onClick={closeMenu}>{t('press')}</NavLink>
              <NavLink to="/event" onClick={closeMenu}>{t('events')}</NavLink>
              <NavLink to="/social-media" onClick={closeMenu}>{t('socialMedia')}</NavLink>
              <div className="menu-divider"></div>
              <NavLink to="/admin/login" onClick={closeMenu} className="admin-link">Admin Login</NavLink>
            </div>
          </nav>

          <Routes>
            <Route path="/impact" element={<Impact />} />
            <Route path="/journey" element={<Journey />} />
            <Route path="/video-gallery" element={<VideoGallery />} />
            <Route path="/video-gallery/:folderId" element={<VideoGallery />} />
            <Route path="/image-gallery" element={<ImageGallery />} />
            <Route path="/image-gallery/:folderId" element={<ImageGallery />} />
            <Route path="/press" element={<Press />} />
            <Route path="/event" element={<Event />} />
            <Route path="/social-media" element={<SocialMedia />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/*" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
            <Route path="/" element={<Home />} />
          </Routes>

          <StickyNav isMenuOpen={isMenuOpen} />

          <footer className="footer" style={{
            background: 'linear-gradient(135deg, var(--primary-blue), var(--secondary-blue))',
            color: 'white',
            padding: '4rem 2rem 2rem',
            marginTop: 'auto'
          }}>
            <div style={{
              maxWidth: '1200px',
              margin: '0 auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
              gap: '3rem'
            }}>
              {/* About Section */}
              <div style={{ animation: 'fadeInUp 0.5s ease-out' }}>
                <h3 style={{
                  fontSize: '1.5rem',
                  marginBottom: '1.5rem',
                  color: 'white',
                  position: 'relative',
                  paddingBottom: '0.5rem'
                }}>
                  {t('aboutAshwani')}
                </h3>
                <p style={{ lineHeight: '1.6', marginBottom: '1rem', color: 'rgba(255, 255, 255, 0.9)' }}>
                  {t('aboutDescription')}
                </p>
              </div>

              {/* Quick Links */}
              <div style={{ animation: 'fadeInUp 0.5s ease-out 0.2s' }}>
                <h3 style={{
                  fontSize: '1.5rem',
                  marginBottom: '1.5rem',
                  color: 'white',
                  position: 'relative',
                  paddingBottom: '0.5rem'
                }}>
                  {t('quickLinks')}
                </h3>
                <div style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '1rem'
                }}>
                  <a href="/" style={{ color: 'rgba(255, 255, 255, 0.9)', textDecoration: 'none', transition: 'color 0.3s ease' }}>{t('home')}</a>
                  <a href="/impact" style={{ color: 'rgba(255, 255, 255, 0.9)', textDecoration: 'none', transition: 'color 0.3s ease' }}>{t('impact')}</a>
                  <a href="/journey" style={{ color: 'rgba(255, 255, 255, 0.9)', textDecoration: 'none', transition: 'color 0.3s ease' }}>{t('journey')}</a>
                  <a href="/events" style={{ color: 'rgba(255, 255, 255, 0.9)', textDecoration: 'none', transition: 'color 0.3s ease' }}>{t('events')}</a>
                  <a href="/gallery" style={{ color: 'rgba(255, 255, 255, 0.9)', textDecoration: 'none', transition: 'color 0.3s ease' }}>{t('gallery')}</a>
                  <a href="/contact" style={{ color: 'rgba(255, 255, 255, 0.9)', textDecoration: 'none', transition: 'color 0.3s ease' }}>{t('contact')}</a>
                </div>
              </div>

              {/* Contact Information */}
              <div style={{ animation: 'fadeInUp 0.5s ease-out 0.4s' }}>
                <h3 style={{
                  fontSize: '1.5rem',
                  marginBottom: '1.5rem',
                  color: 'white',
                  position: 'relative',
                  paddingBottom: '0.5rem'
                }}>
                  {t('contact')}
                </h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <p style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', color: 'rgba(255, 255, 255, 0.9)' }}>
                    📍 <span>{t('address')}: Pashuram Colony, Sandalpur more, Kumhrar, Patna 800007</span>
                  </p>
                  <p style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', color: 'rgba(255, 255, 255, 0.9)' }}>
                    📞 <span>{t('phone')}: 6123587207</span>
                  </p>
                  <p style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', color: 'rgba(255, 255, 255, 0.9)' }}>
                    ✉️ <span>{t('email')}: pankajsinha@ashwanikumar.org</span>
                  </p>
                </div>
              </div>

              {/* Social Media */}
              <div style={{ animation: 'fadeInUp 0.5s ease-out 0.6s' }}>
                <h3 style={{
                  fontSize: '1.5rem',
                  marginBottom: '1.5rem',
                  color: 'white',
                  position: 'relative',
                  paddingBottom: '0.5rem'
                }}>
                  {t('connectWithUs')}
                </h3>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <a href="https://www.facebook.com/share/1ENGY9SK7D/" style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: 'rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    transition: 'all 0.3s ease'
                  }}>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://x.com/ipankajsinha88?t=CnXTHubADmxu2EMk0i_uow&s=09" style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: 'rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    transition: 'all 0.3s ease'
                  }}>
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.instagram.com/ipankajsinha?igsh=NHM3ZzIwaGU1M3V2" style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: 'rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    transition: 'all 0.3s ease'
                  }}>
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#youtube" style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: 'rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    transition: 'all 0.3s ease'
                  }}>
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* Footer Bottom */}
            <div style={{
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              marginTop: '3rem',
              paddingTop: '2rem',
              textAlign: 'center'
            }}>
              <p style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                © {new Date().getFullYear()} Ashwani Kumar. All rights reserved.
              </p>
            </div>
          </footer>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
