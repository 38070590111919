import React, { useState } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('dashboard');

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin/login');
  };

  const menuItems = [
    { id: 'dashboard', label: 'Dashboard', icon: '📊' },
    { id: 'image-gallery', label: 'Image Gallery', icon: '🖼️' },
    { id: 'video-gallery', label: 'Video Gallery', icon: '🎥' },
    { id: 'events', label: 'Events', icon: '📅' },
    { id: 'social-media', label: 'Social Media', icon: '🌐' }
  ];

  const DashboardHome = () => (
    <div style={{ padding: '2rem' }}>
      <h2 style={{ marginBottom: '2rem', color: 'var(--primary-blue)' }}>Welcome to Admin Dashboard</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '2rem' }}>
        {menuItems.slice(1).map(item => (
          <div
            key={item.id}
            onClick={() => navigate(`/admin/${item.id}`)}
            style={{
              background: 'white',
              padding: '2rem',
              borderRadius: '15px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              transition: 'transform 0.3s ease',
              ':hover': {
                transform: 'translateY(-5px)'
              }
            }}
          >
            <div style={{ fontSize: '2rem', marginBottom: '1rem' }}>{item.icon}</div>
            <h3 style={{ color: 'var(--primary-blue)' }}>{item.label}</h3>
            <p style={{ color: 'var(--dark-gray)', marginTop: '0.5rem' }}>
              Manage {item.label.toLowerCase()} content
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      {/* Sidebar */}
      <div style={{
        width: '250px',
        background: 'var(--primary-blue)',
        color: 'white',
        padding: '2rem 0',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{ padding: '0 1.5rem', marginBottom: '2rem' }}>
          <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Admin Panel</h2>
          <p style={{ fontSize: '0.9rem', color: 'rgba(255, 255, 255, 0.7)' }}>Manage your website</p>
        </div>

        <nav style={{ flex: 1 }}>
          {menuItems.map(item => (
            <Link
              key={item.id}
              to={`/admin/${item.id}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '1rem 1.5rem',
                color: 'white',
                textDecoration: 'none',
                background: activeSection === item.id ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                transition: 'background 0.3s ease'
              }}
              onClick={() => setActiveSection(item.id)}
            >
              <span style={{ marginRight: '1rem' }}>{item.icon}</span>
              {item.label}
            </Link>
          ))}
        </nav>

        <button
          onClick={handleLogout}
          style={{
            margin: '1.5rem',
            padding: '0.75rem',
            background: 'rgba(255, 255, 255, 0.1)',
            border: 'none',
            borderRadius: '8px',
            color: 'white',
            cursor: 'pointer',
            transition: 'background 0.3s ease'
          }}
        >
          Logout
        </button>
      </div>

      {/* Main Content */}
      <div style={{ flex: 1, background: '#f5f5f5', overflow: 'auto' }}>
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/dashboard" element={<DashboardHome />} />
          <Route path="/image-gallery" element={<ImageGalleryManager />} />
          <Route path="/video-gallery" element={<VideoGalleryManager />} />
          <Route path="/events" element={<EventManager />} />
          <Route path="/social-media" element={<SocialMediaManager />} />
        </Routes>
      </div>
    </div>
  );
};

// Placeholder components for each section
const ImageGalleryManager = () => (
  <div style={{ padding: '2rem' }}>
    <h2 style={{ marginBottom: '2rem', color: 'var(--primary-blue)' }}>Image Gallery Management</h2>
    {/* Add image gallery management interface here */}
  </div>
);

const VideoGalleryManager = () => (
  <div style={{ padding: '2rem' }}>
    <h2 style={{ marginBottom: '2rem', color: 'var(--primary-blue)' }}>Video Gallery Management</h2>
    {/* Add video gallery management interface here */}
  </div>
);

const EventManager = () => (
  <div style={{ padding: '2rem' }}>
    <h2 style={{ marginBottom: '2rem', color: 'var(--primary-blue)' }}>Event Management</h2>
    {/* Add event management interface here */}
  </div>
);

const SocialMediaManager = () => (
  <div style={{ padding: '2rem' }}>
    <h2 style={{ marginBottom: '2rem', color: 'var(--primary-blue)' }}>Social Media Management</h2>
    {/* Add social media management interface here */}
  </div>
);

export default AdminDashboard; 