import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import '../styles/SharedAnimations.css';
import vid1 from '../images/vid1.mp4';
import vid2 from '../images/vid2.mp4';
import vid3 from '../images/vid3.mp4';

// Video folder data structure
const videoFolders = {
  'community-service': {
    title: 'Community Service',
    description: 'Videos showcasing our community service initiatives and social work.',
    thumbnail: 'https://via.placeholder.com/300x200',
    videos: [
      {
        id: 1,
        title: 'Food Distribution Drive',
        url: vid1,
        date: '2024-01-15',
        description: 'Distribution of food packages to underprivileged families.'
      },
      {
        id: 2,
        title: 'Medical Camp',
        url: vid2,
        date: '2024-01-10',
        description: 'Free medical checkup camp organized for the community.'
      }
    ]
  },
  'public-gathering': {
    title: 'Public Gathering',
    description: 'Coverage of public meetings, rallies, and addresses.',
    thumbnail: 'https://via.placeholder.com/300x200',
    videos: [
      {
        id: 3,
        title: 'Town Hall Meeting',
        url: vid3,
        date: '2024-01-05',
        description: 'Interactive session with citizens about local development.'
      },
      {
        id: 4,
        title: 'Youth Conference',
        url: vid1,
        date: '2023-12-25',
        description: 'Annual youth conference discussing future opportunities.'
      }
    ]
  },
  'cultural-event': {
    title: 'Cultural Event',
    description: 'Celebrations of our rich cultural heritage and festivals.',
    thumbnail: 'https://via.placeholder.com/300x200',
    videos: [
      {
        id: 5,
        title: 'Traditional Dance Performance',
        url: vid2,
        date: '2023-12-20',
        description: 'Showcase of traditional dance forms.'
      },
      {
        id: 6,
        title: 'Festival Celebrations',
        url: vid3,
        date: '2023-12-15',
        description: 'Community festival celebrations and cultural programs.'
      }
    ]
  }
};

const VideoGallery = () => {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [selectedVideo, setSelectedVideo] = useState(null);

  const VideoModal = ({ video, onClose }) => (
    <div className="video-modal-overlay" onClick={onClose}>
      <div className="video-modal-content" onClick={e => e.stopPropagation()}>
        <button className="video-modal-close" onClick={onClose} aria-label="Close video">×</button>
        <div className="video-container">
          <video controls width="100%" height="auto" autoPlay>
            <source src={video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-modal-info">
          <h3>{video.title}</h3>
          <p>{video.description}</p>
          <p className="video-date">Published on: {new Date(video.date).toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );

  // If we're in a specific folder
  if (folderId && videoFolders[folderId]) {
    const folder = videoFolders[folderId];
    return (
      <div className="page-container">
        <div style={{ marginBottom: '2rem' }}>
          <Link 
            to="/video-gallery" 
            style={{ 
              color: 'var(--primary-blue)', 
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}
          >
            ← Back to Folders
          </Link>
        </div>
        <h1 className="page-title">{folder.title}</h1>
        <p style={{ textAlign: 'center', marginBottom: '2rem' }}>{folder.description}</p>

        <section className="section">
          <div className="video-grid">
            {folder.videos.map(video => (
              <div
                key={video.id}
                className="video-card"
                onClick={() => setSelectedVideo(video)}
              >
                <div className="thumbnail-container">
                  <video
                    src={video.url}
                    className="video-thumbnail"
                    preload="metadata"
                  />
                  <div className="play-button">
                    <svg viewBox="0 0 24 24" fill="currentColor">
                      <path d="M8 5v14l11-7z"/>
                    </svg>
                  </div>
                </div>
                <div className="video-info">
                  <h3>{video.title}</h3>
                  <p className="video-description">{video.description}</p>
                  <p className="video-date">
                    {new Date(video.date).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {selectedVideo && (
          <VideoModal
            video={selectedVideo}
            onClose={() => setSelectedVideo(null)}
          />
        )}
      </div>
    );
  }

  // Main folder view
  return (
    <div className="page-container">
      <h1 className="page-title">Video Gallery</h1>

      <section className="section">
        <div className="video-grid">
          {Object.entries(videoFolders).map(([folderId, folder]) => (
            <div
              key={folderId}
              className="video-card folder-card"
              onClick={() => navigate(`/video-gallery/${folderId}`)}
              style={{ cursor: 'pointer' }}
            >
              <div className="thumbnail-container" style={{ position: 'relative' }}>
                <div 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(135deg, var(--primary-blue), var(--secondary-blue))',
                    opacity: 0.9,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.5rem',
                    color: 'white',
                    fontWeight: '500'
                  }}
                >
                  View Videos
                </div>
              </div>
              <div className="video-info">
                <h3>{folder.title}</h3>
                <p className="video-description">{folder.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default VideoGallery; 