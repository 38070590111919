import React, { useState } from 'react';
import '../styles/SharedAnimations.css';
import healthMinister from '../images/healthMinister.jpg';
import metLead5 from '../images/metLead5.jpg';
import img1 from '../images/img1.jpg';
import facebook2 from '../images/facebook2.jpg';
import x1 from '../images/x1.jpeg';
import x3 from '../images/x3.jpeg';
const SocialMedia = () => {
  const [activeTab, setActiveTab] = useState('twitter');

  // Sample social media data
  const socialMediaData = {
    twitter: [
      {
        id: 1,
        content: 'पटना के नब्बे फिट निवासी पटना एम्स के डॉ मणि राज जी के यहाँ गृह प्रवेश कार्यक्रम में सम्मिलित होकर बधाई एवं शुभकामनाएं दिया। ',
        date: '2024-01-25T10:30:00',
        image: x1,
        likes: 1500,
        retweets: 800,
        comments: 200,
        link: 'https://x.com/ipankajsinha88/status/1888583648271536629'
      },
      {
        id: 2,
        content: 'आज कार्यालय में कुम्हरार विधानसभा से आए सभी स्लम प्रमुखों का अंगवस्त्र देकर सम्मानित कर बैठक को संबोधित किया।',
        date: '2024-01-24T15:45:00',
        image: x3,
        likes: 1200,
        retweets: 600,
        comments: 150,
        link: 'https://x.com/ipankajsinha88/status/1887547118442979341'
      },
      // Add more tweets
    ],
    facebook: [
      {
        id: 1,
        content: 'विजय भव:…',
        date: '2024-01-25T09:00:00',
        image: img1,
        likes: 2500,
        shares: 1000,
        comments: 300,
        link: 'https://www.facebook.com/ipankajsinha/videos/1159805112422529/'
      },
      {
        id: 2,
        content: 'आज गायघाट स्थित पौराणिक संकट मोचन हनुमान मंदिर एवं दुर्गा स्थान में राधे कृष्ण दरबार के सातवें स्थापन दिवस पर उपस्थित होकर क्षेत्र वासियों को शुभकामनाएं दिया।',
        date: '2024-01-24T14:30:00',
        image: facebook2,
        likes: 2000,
        shares: 800,
        comments: 250,
        link: 'https://www.facebook.com/share/p/1AHp1getMy/'
      },
      // Add more Facebook posts
    ],
    instagram: [
      {
        id: 1,
        image: metLead5,
        caption: 'Meeting with farmers to discuss agricultural modernization plans. Their insights are invaluable! 🌾 #Agriculture #Development',
        date: '2024-01-25T11:00:00',
        likes: 3500,
        comments: 400,
        link: 'https://www.instagram.com/p/DE7tzScSV1M/?igsh=bWFpM25jYm85eTk1'
      },
      {
        id: 2,
        image: healthMinister,
        caption: 'Inaugurating a new healthcare facility. Quality healthcare for all is our commitment! 🏥 #Healthcare #Progress',
        date: '2024-01-24T16:00:00',
        likes: 3000,
        comments: 350,
        link: 'https://www.instagram.com/p/DE5OtP1N69P/?igsh=MTB0M2ZxbDgzM3J3OA=='
      },
      // Add more Instagram posts
    ]
  };

  const TabButton = ({ id, label, active }) => (
    <button
      onClick={() => setActiveTab(id)}
      className={`animated-tab ${active ? 'active' : ''}`}
    >
      {label}
    </button>
  );

  const SocialButton = ({ platform, link }) => {
    const buttonStyle = {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0.5rem 1rem',
      borderRadius: '20px',
      color: 'white',
      textDecoration: 'none',
      fontSize: '0.9rem',
      fontWeight: '500',
      transition: 'transform 0.3s ease',
      marginTop: '1rem'
    };

    const getButtonStyle = () => {
      switch (platform) {
        case 'twitter':
          return { background: '#1DA1F2' };
        case 'facebook':
          return { background: '#4267B2' };
        case 'instagram':
          return { background: 'linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)' };
        default:
          return { background: '#666' };
      }
    };

    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...buttonStyle, ...getButtonStyle() }}
        className="social-link-button"
      >
        {platform === 'twitter' && 'View on Twitter'}
        {platform === 'facebook' && 'View on Facebook'}
        {platform === 'instagram' && 'View on Instagram'}
        <span style={{ marginLeft: '0.5rem' }}>→</span>
      </a>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'twitter':
        return (
          <div className="social-feed" key="twitter">
            {socialMediaData.twitter.map(tweet => (
              <div key={tweet.id} className="social-card twitter-card">
                {tweet.image && <img src={tweet.image} alt="" className="social-image" />}
                <div className="content">
                  <p>{tweet.content}</p>
                  <div className="social-stats">
                    <span>{new Date(tweet.date).toLocaleString()}</span>
                    <div>
                      <span>❤️ {tweet.likes}</span>
                      <span>🔄 {tweet.retweets}</span>
                      <span>💬 {tweet.comments}</span>
                    </div>
                  </div>
                  <SocialButton platform="twitter" link={tweet.link} />
                </div>
              </div>
            ))}
          </div>
        );

      case 'facebook':
        return (
          <div className="social-feed" key="facebook">
            {socialMediaData.facebook.map(post => (
              <div key={post.id} className="social-card facebook-card">
                <img src={post.image} alt="" className="social-image" />
                <div className="content">
                  <p>{post.content}</p>
                  <div className="social-stats">
                    <span>{new Date(post.date).toLocaleString()}</span>
                    <div>
                      <span>👍 {post.likes}</span>
                      <span>🔄 {post.shares}</span>
                      <span>💬 {post.comments}</span>
                    </div>
                  </div>
                  <SocialButton platform="facebook" link={post.link} />
                </div>
              </div>
            ))}
          </div>
        );

      case 'instagram':
        return (
          <div className="social-feed instagram-grid" key="instagram">
            {socialMediaData.instagram.map(post => (
              <div key={post.id} className="social-card instagram-card">
                <img src={post.image} alt="" className="social-image" />
                <div className="content">
                  <p>{post.caption}</p>
                  <div className="social-stats">
                    <span>{new Date(post.date).toLocaleString()}</span>
                    <div>
                      <span>❤️ {post.likes}</span>
                      <span>💬 {post.comments}</span>
                    </div>
                  </div>
                  <SocialButton platform="instagram" link={post.link} />
                </div>
              </div>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="page-container">
      <h1 className="page-title">Social Media</h1>

      <section className="section">
        <div className="social-tabs">
          <TabButton
            id="twitter"
            label="Twitter"
            active={activeTab === 'twitter'}
          />
          <TabButton
            id="facebook"
            label="Facebook"
            active={activeTab === 'facebook'}
          />
          <TabButton
            id="instagram"
            label="Instagram"
            active={activeTab === 'instagram'}
          />
        </div>

        <div className="social-content">
          {renderContent()}
        </div>
      </section>

      <style jsx>{`
        .social-link-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }
        
        .social-card {
          position: relative;
          overflow: hidden;
        }
        
        .social-stats {
          margin-bottom: 0.5rem;
        }
      `}</style>
    </div>
  );
};

export default SocialMedia;