import React, { useState, useEffect } from 'react';
import img1 from '../images/img1.jpg';
import img2 from '../images/img2.jpg';
import img3 from '../images/img3.jpg';
import './Impact.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Impact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Carousel data
  const carouselData = [
    {
      image: img1,
      quote: "Leadership is not about being in charge. It's about taking care of those in your charge.",
      author: "Ashwani Kumar"
    },
    {
      image: img2,
      quote: "Together we can build a stronger, more prosperous nation for all.",
      author: "Ashwani Kumar"
    },
    {
      image: img3,
      quote: "Progress is not just about development, it's about inclusive growth that benefits everyone.",
      author: "Ashwani Kumar"
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});
  const [hoveredCard, setHoveredCard] = useState(null);
  const [currentAchievement, setCurrentAchievement] = useState(0);

  // Auto-advance carousel
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % carouselData.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  // Auto-scroll achievements
  useEffect(() => {
    const achievementTimer = setInterval(() => {
      setCurrentAchievement((prev) => (prev + 1) % achievements.length);
      const timelineContainer = document.querySelector('.timeline-horizontal');
      if (timelineContainer) {
        const itemWidth = timelineContainer.scrollWidth / achievements.length;
        timelineContainer.scrollTo({
          left: itemWidth * ((currentAchievement + 1) % achievements.length),
          behavior: 'smooth'
        });
      }
    }, 4000);

    return () => clearInterval(achievementTimer);
  }, [currentAchievement]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % carouselData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + carouselData.length) % carouselData.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const toggleCard = (index) => {
    setExpandedCards(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Impact data
  const impactData = {
    center: {
      title: "22 years in Public Life",
      subtitle: "Journey from a Student Leader to Prominent figure in BIHAR."
    },
    cards: [
      {
        title: t('culturalBeliefTitle'),
        description: t('culturalBeliefDesc'),
        image: img1,
      },
      {
        title: t('dedicationTitle'),
        description: t('dedicationDesc'),
        image: img2,
      },
      {
        title: t('traditionTitle'),
        description: t('traditionDesc'),
        image: img3,
      },
      {
        title: t('inspirationTitle'),
        description: t('inspirationDesc'),
        image: img1,
      },
      {
        title: t('educationTitle'),
        description: t('educationDesc'),
        image: img2,
      },
      {
        title: t('communityTitle'),
        description: t('communityDesc'),
        image: img3,
      },
    ]
  };

  // Stats data
  const statsData = [
    { number: "500+", label: t('publicEvents'), icon: "🎯" },
    { number: "500+", label: t('developmentProjects'), icon: "🏗️" },
    { number: "10L+", label: t('livesImpacted'), icon: "👥" },
    { number: "22+", label: t('yearsOfService'), icon: "⭐" }
  ];

  // Achievements data
  const achievements = [
    {
      year: "2023",
      title: t('leadershipAward'),
      description: t('leadershipDesc'),
      icon: "🏆"
    },
    {
      year: "2022",
      title: t('digitalInitiative'),
      description: t('digitalDesc'),
      icon: "💻"
    },
    {
      year: "2021",
      title: t('educationReform'),
      description: t('educationReformDesc'),
      icon: "📚"
    }
  ];

  // Testimonials data
  const testimonials = [
    {
      quote: "Dedicated to development and welfare of the people",
      author: "Sita Sahu",
      position: "Mayor Patna"
    },
    {
      quote: "He has a very sharp vision and will bring significant changes",
      author: "R K Sinha",
      position: "Purva Rajyasabha Sansad"
    },
    {
      quote: "",
      author: "",
      position: ""
    }
  ];

  return (
    <div style={{ overflow: 'hidden' }}>
      {/* Carousel Section */}
      <div style={{
        position: 'relative',
        width: '100vw',
        height: '700px',
        overflow: 'hidden',
        marginBottom: '2rem',
        left: '50%',
        transform: 'translateX(-50%)'
      }}>
        {carouselData.map((slide, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: index === currentSlide ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
            <div style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '4rem 2rem',
              background: 'linear-gradient(transparent, rgba(0, 0, 0, 0.8))',
              color: 'white',
              textAlign: 'center'
            }}>
              <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                <p style={{
                  fontSize: '2rem',
                  fontStyle: 'italic',
                  marginBottom: '1rem',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
                }}>"{slide.quote}"</p>
                <p style={{ fontSize: '1.2rem', fontWeight: '500' }}>- {slide.author}</p>
              </div>
            </div>
          </div>
        ))}

        {/* Navigation Arrows */}
        <button onClick={prevSlide} className="nav-button" style={{ left: '2rem' }}>←</button>
        <button onClick={nextSlide} className="nav-button" style={{ right: '2rem' }}>→</button>

        {/* Dot Indicators */}
        <div style={{
          position: 'absolute',
          bottom: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: '0.8rem',
          zIndex: 10
        }}>
          {carouselData.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                border: 'none',
                background: index === currentSlide ? '#FF7F50' : 'rgba(255, 255, 255, 0.7)',
                cursor: 'pointer',
                transition: 'all 0.3s ease'
              }}
            />
          ))}
        </div>
      </div>

      {/* Impact Section */}
      <div className="impact-section">
        {/* Stats Section */}
        <div className="stats-container">
          {statsData.map((stat, index) => (
            <div key={index} className="stat-card">
              <div className="stat-icon">{stat.icon}</div>
              <h3>{stat.number}</h3>
              <p>{stat.label}</p>
            </div>
          ))}
        </div>

        <div className="impact-container">
          {/* Left Circle */}
          <div className="center-circle">
            <div className="circle-content">
              <h2>{impactData.center.title}</h2>
              <p>{impactData.center.subtitle}</p>
            </div>
          </div>

          {/* Impact Cards Grid */}
          <div className="impact-cards">
            {impactData.cards.map((card, index) => (
              <div 
                key={index} 
                className="office-card"
                onClick={() => setSelectedCard(card)}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
                style={{
                  background: hoveredCard === index 
                    ? 'linear-gradient(135deg, var(--primary-blue), var(--secondary-blue))'
                    : 'white',
                  padding: '2rem',
                  borderRadius: '15px',
                  boxShadow: hoveredCard === index 
                    ? '0 15px 40px rgba(1, 42, 74, 0.15)'
                    : '0 10px 30px rgba(1, 42, 74, 0.1)',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  border: '1px solid rgba(1, 42, 74, 0.1)',
                  transform: hoveredCard === index ? 'translateY(-5px)' : 'translateY(0)'
                }}
              >
                <h3 style={{
                  color: hoveredCard === index ? 'white' : 'var(--primary-blue)',
                  marginBottom: '1.5rem',
                  fontSize: '1.5rem',
                  borderBottom: hoveredCard === index 
                    ? '1px solid rgba(255, 255, 255, 0.2)'
                    : '1px solid rgba(1, 42, 74, 0.1)',
                  paddingBottom: '0.75rem',
                  transition: 'all 0.3s ease'
                }}>
                  {card.title}
                </h3>
                {card.subtitle && (
                  <h4 style={{
                    color: hoveredCard === index ? 'rgba(255, 255, 255, 0.9)' : 'var(--secondary-blue)',
                    marginBottom: '1rem',
                    transition: 'color 0.3s ease'
                  }}>
                    {card.subtitle}
                  </h4>
                )}
                <p style={{
                  color: hoveredCard === index ? 'rgba(255, 255, 255, 0.9)' : 'var(--dark-gray)',
                  transition: 'color 0.3s ease',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  marginBottom: '0'
                }}>
                  {card.description}
                </p>
              </div>
            ))}
          </div>

          {/* Card Modal */}
          {selectedCard && (
            <div 
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                padding: '2rem',
                cursor: 'pointer'
              }}
              onClick={() => setSelectedCard(null)}
            >
              <div 
                style={{
                  background: 'white',
                  padding: '2.5rem',
                  borderRadius: '20px',
                  maxWidth: '600px',
                  width: '90%',
                  maxHeight: '90vh',
                  overflow: 'auto',
                  position: 'relative',
                  cursor: 'default',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
                  animation: 'modalFadeIn 0.3s ease-out'
                }}
                onClick={e => e.stopPropagation()}
              >
                <button
                  onClick={() => setSelectedCard(null)}
                  style={{
                    position: 'absolute',
                    right: '1.5rem',
                    top: '1.5rem',
                    background: 'none',
                    border: 'none',
                    fontSize: '1.5rem',
                    color: 'var(--primary-blue)',
                    cursor: 'pointer',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    transition: 'all 0.3s ease'
                  }}
                  onMouseEnter={e => {
                    e.target.style.transform = 'rotate(90deg)';
                    e.target.style.backgroundColor = 'rgba(1, 42, 74, 0.1)';
                  }}
                  onMouseLeave={e => {
                    e.target.style.transform = 'rotate(0deg)';
                    e.target.style.backgroundColor = 'transparent';
                  }}
                >
                  ×
                </button>
                <h3 style={{
                  color: 'var(--primary-blue)',
                  fontSize: '1.8rem',
                  marginBottom: '1.5rem',
                  paddingBottom: '1rem',
                  borderBottom: '2px solid rgba(1, 42, 74, 0.1)'
                }}>
                  {selectedCard.title}
                </h3>
                {selectedCard.subtitle && (
                  <h4 style={{
                    color: 'var(--secondary-blue)',
                    fontSize: '1.2rem',
                    marginBottom: '1rem'
                  }}>
                    {selectedCard.subtitle}
                  </h4>
                )}
                <p style={{
                  fontSize: '1.1rem',
                  lineHeight: '1.6',
                  color: 'var(--dark-gray)'
                }}>
                  {selectedCard.description}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Achievements Timeline */}
        <div className="achievements-section">
          <h2 className="section-title">Key Achievements</h2>
          <div className="timeline-horizontal" style={{ scrollBehavior: 'smooth' }}>
            <div className="timeline-line"></div>
            {achievements.map((achievement, index) => (
              <div 
                key={index} 
                className={`timeline-item-horizontal ${index === currentAchievement ? 'active' : ''}`}
                style={{
                  opacity: index === currentAchievement ? 1 : 0.7,
                  transform: index === currentAchievement ? 'scale(1.05)' : 'scale(1)',
                  transition: 'all 0.5s ease'
                }}
              >
                <div className="timeline-dot"></div>
                <div className="timeline-content-horizontal">
                  <div className="timeline-year-horizontal">{achievement.year}</div>
                  <div className="timeline-card">
                    <h3>{achievement.title}</h3>
                    <p>{achievement.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="testimonials-section">
          <h2 className="section-title">What People Say</h2>
          <div className="testimonials-container">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <div className="quote-icon">"</div>
                <p className="testimonial-quote">{testimonial.quote}</p>
                <div className="testimonial-author">
                  <h4>{testimonial.author}</h4>
                  <p>{testimonial.position}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="cta-section">
          <div className="cta-content">
            <h2>Join the Movement</h2>
            <p>Be part of the change and help build a better tomorrow</p>
            <button 
              className="cta-button" 
              onClick={() => navigate('/contact')}
              style={{
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                transform: 'scale(1)',
                ':hover': {
                  transform: 'scale(1.05)'
                }
              }}
            >
              Get Involved
            </button>
          </div>
          <div className="cta-image">
            <img src={img1} alt="Join our movement" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impact; 