import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/SharedAnimations.css';
import pfp1 from '../images/pfp1.png'

const Journey = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const { t } = useTranslation();

  // Journey timeline data
  const journeyData = [
    {
      title: t('introduction'),
      description: t('introductionDesc')
    },
    {
      title: t('marriedLife'),
      description: t('marriedLifeDesc')
    },
    {
      title: t('education'),
      description: t('educationDesc')
    },
    {
      title: t('leadership'),
      description: t('leadershipDesc')
    },
    {
      title: t('entrepreneur'),
      description: t('entrepreneurDesc')
    }
  ];

  // Core values data
  const coreValues = [
    {
      title: t('integrity'),
      description: t('integrityDesc')
    },
    {
      title: t('development'),
      description: t('developmentDesc')
    },
    {
      title: t('kumhrarFirst'),
      description: t('kumhrarFirstDesc')
    }
  ];

  return (
    <div className="page-container">
      <h1 className="page-title">{t('politicalJourney')}</h1>

      <section className="section">
        <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
          <div className="animated-card" style={{ 
            width: '200px', 
            height: '200px', 
            borderRadius: '50%', 
            backgroundColor: 'var(--light-gray)', 
            margin: '0 auto 1rem',
            border: '4px solid var(--primary-blue)',
            padding: '0',
            overflow: 'hidden'
          }}>
            <img 
              src={pfp1} 
              alt="Ashwani Kumar"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'cover',
                display: 'block',
                margin: '0'
              }}
            />
          </div>
          <h2 style={{ color: 'var(--primary-blue)' }}>Ashwani Kumar</h2>
          <p style={{ fontSize: '1.2rem', color: 'var(--dark-gray)' }}>{t('servingSince')} 2002</p>
        </div>

        <div className="journey-timeline" style={{ position: 'relative', maxWidth: '800px', margin: '0 auto' }}>
          {journeyData.map((item, index) => (
            <div 
              key={index} 
              className="animated-timeline-item"
              onClick={() => setSelectedCard(item)}
              style={{ cursor: 'pointer' }}
            >
              <div style={{ 
                position: 'absolute',
                left: '-10px',
                width: '20px',
                height: '20px',
                backgroundColor: 'var(--primary-blue)',
                borderRadius: '50%'
              }}></div>
              <h3>{item.title}</h3>
              <p style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                marginBottom: '0'
              }}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      <section className="section">
        <h2 style={{ color: 'var(--primary-blue)', marginBottom: '2rem', textAlign: 'center' }}>{t('coreValues')}</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '2rem' }}>
          {coreValues.map((value, index) => (
            <div 
              key={index} 
              className="animated-card" 
              style={{ textAlign: 'center', padding: '1.5rem', cursor: 'pointer' }}
              onClick={() => setSelectedCard(value)}
            >
              <h3>{value.title}</h3>
              <p style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                marginBottom: '0'
              }}>
                {value.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Card Modal */}
      {selectedCard && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            padding: '2rem',
            cursor: 'pointer'
          }}
          onClick={() => setSelectedCard(null)}
        >
          <div 
            style={{
              background: 'white',
              padding: '2.5rem',
              borderRadius: '20px',
              maxWidth: '600px',
              width: '90%',
              maxHeight: '90vh',
              overflow: 'auto',
              position: 'relative',
              cursor: 'default',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
              animation: 'modalFadeIn 0.3s ease-out'
            }}
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={() => setSelectedCard(null)}
              style={{
                position: 'absolute',
                right: '1.5rem',
                top: '1.5rem',
                background: 'none',
                border: 'none',
                fontSize: '1.5rem',
                color: 'var(--primary-blue)',
                cursor: 'pointer',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                transition: 'all 0.3s ease'
              }}
              onMouseEnter={e => {
                e.target.style.transform = 'rotate(90deg)';
                e.target.style.backgroundColor = 'rgba(1, 42, 74, 0.1)';
              }}
              onMouseLeave={e => {
                e.target.style.transform = 'rotate(0deg)';
                e.target.style.backgroundColor = 'transparent';
              }}
            >
              ×
            </button>
            <h3 style={{
              color: 'var(--primary-blue)',
              fontSize: '1.8rem',
              marginBottom: '1.5rem',
              paddingBottom: '1rem',
              borderBottom: '2px solid rgba(1, 42, 74, 0.1)'
            }}>
              {selectedCard.title}
            </h3>
            <p style={{
              fontSize: '1.1rem',
              lineHeight: '1.6',
              color: 'var(--dark-gray)',
              whiteSpace: 'pre-line'
            }}>
              {selectedCard.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Journey; 