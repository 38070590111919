import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SharedAnimations.css';

const StickyNav = ({ isMenuOpen }) => {
  const navigate = useNavigate();

  const navItems = [
    { label: 'Listen', path: '/impact' },
    { label: 'Act', path: '/event' },
    { label: 'Share', path: '/social-media' }
  ];

  return (
    <div className={`sticky-nav ${isMenuOpen ? 'hide' : ''}`}>
      {navItems.map((item, index) => (
        <button
          key={index}
          className="sticky-nav-item"
          onClick={() => navigate(item.path)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default StickyNav; 