import React from 'react';
import '../styles/SharedAnimations.css';
import news1 from '../images/news1.jpg';

const Press = () => {
  // Sample press data
  const pressData = [
    {
      id: 1,
      title: "युवाओं के लिए पटना में ऐतिहासिक समारोह, उपमुख्यमंत्री सहित क़ई मंत्री रहेंगे मौजूद!",
      source: "Youtube",
      date: "",
      summary: "The contribution of all saints is very high, whenever the need arises, it played a significant role in protecting the culture.",
      image: news1,
      views: 1500,
      url: "https://youtu.be/GdwZ2K6xpd8"
    },
    {
      id: 2,
      title: "Patna_City में सुभाष चंद्र बोस की जयंती पर दिखेगा युवा एकता, कई मंत्री होंगे मौजूद |",
      source: "YouTube",
      date: "May 10, 2023",
      summary: "Implementation of comprehensive education reforms reaching over schools and benefiting students across the nation.",
      image: news1,
      views: 1200,
      url: "https://youtu.be/Yb0di_8R9ik?si=wDvPdp2G9V_nedzR"
    },
    {
      id: 3,
      title: "23 जनवरी को नेताजी की जयंती पर युवा सम्मान समारोह सह कंबल वितरण का होने जा रहा आयोजन",
      source: "YouTube",
      date: "Mar 02, 2023",
      summary: "Strengthening democratic values and ensuring participation at all levels of governance.",
      image: news1,
      views: 2000,
      url: "https://youtu.be/W3_o3OLgYvo"
    },
    {
      id: 4,
      title: "कुम्हरार में समाज सेवा की नई मिसाल!",
      source: "YouTube",
      date: "Mar 04, 2023",
      summary: "Emphasizing the importance of technology in modern governance and public outreach.",
      image: news1,
      views: 1800,
      url: "https://youtu.be/_mooBSw5uJs?si=WWes6SHKXMjYnNzT"
    }
  ];

  const popularPosts = [...pressData].sort((a, b) => b.views - a.views).slice(0, 2);

  return (
    <div className="page-container">
      <h1 className="page-title">Press Coverage</h1>

      <section className="section">
        <div className="press-container">
          <div className="press-main">
            <h2 className="section-title">Latest News</h2>
            <div className="press-grid">
              {pressData.map(article => (
                <div key={article.id} className="press-card">
                  <div className="press-image-container">
                    <img src={article.image} alt={article.title} />
                    <div className="press-source">{article.source}</div>
                  </div>
                  <div className="press-content">
                    <h3>{article.title}</h3>
                    <p>{article.summary}</p>
                    <div className="press-footer">
                      <span className="press-date">{article.date}</span>
                      <a 
                        href={article.url} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="press-read-more"
                        style={{
                          textDecoration: 'none',
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          transition: 'transform 0.3s ease'
                        }}
                      >
                        Read More 
                        <span style={{ fontSize: '1.2em' }}>→</span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="press-sidebar">
            <h2 className="section-title">Most Popular</h2>
            <div className="popular-posts">
              {popularPosts.map(article => (
                <a
                  key={article.id}
                  href={article.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="popular-post-card">
                    <img src={article.image} alt={article.title} />
                    <div className="popular-post-content">
                      <h3>{article.title}</h3>
                      <div className="popular-post-meta">
                        <span>{article.source}</span>
                        <span>{article.date}</span>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Press; 