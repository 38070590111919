import React from 'react';
import '../styles/SharedAnimations.css';


const Event = () => {
  // Sample event data
  const events = {
    past: [
      // {
      //   id: 3,
      //   title: 'Rural Development Conference',
      //   date: '2024-01-10',
      //   time: '9:00 AM',
      //   location: 'Rural Community Center',
      //   address: '789 Village Road, County',
      //   description: 'Conference on rural development initiatives and agricultural modernization.',
      //   image: 'https://via.placeholder.com/400x300',
      //   highlights: ['Over 500 attendees', 'Launch of new farming initiative', 'Distribution of agricultural equipment']
      // },
      {
        id: 4,
        title: 'Education Reform Meeting',
        date: '2024-01-05',
        time: '11:00 AM',
        location: 'State Education Center',
        address: '321 School Lane, City',
        description: 'Discussion on education reforms and implementation of new learning methodologies.',
        image: 'https://via.placeholder.com/400x300',
        highlights: ['New education policy announced', 'Partnership with leading institutions', 'Digital learning initiative launch']
      }
    ],
    upcoming: [
      {
        id: 1,
        title: 'Town Hall Meeting',
        date: '2024-02-15',
        time: '10:00 AM',
        location: 'City Convention Center',
        address: '123 Main Street, City',
        description: 'Open discussion with citizens about local development plans and addressing community concerns.',
        image: 'https://via.placeholder.com/400x300'
      },
      // {
      //   id: 2,
      //   title: 'Youth Employment Summit',
      //   date: '2024-02-20',
      //   time: '2:00 PM',
      //   location: 'Grand Hotel',
      //   address: '456 Park Avenue, City',
      //   description: 'Summit focusing on youth employment opportunities and skill development initiatives.',
      //   image: 'https://via.placeholder.com/400x300'
      // }
    ]
  };

  const EventCard = ({ event, isPast }) => (
    <div className={`animated-event-card ${isPast ? 'past-event' : ''}`}>
      <div style={{ display: 'flex', flexDirection: 'column', '@media (min-width: 768px)': { flexDirection: 'row' } }}>
        <div style={{ flex: '0 0 40%', maxWidth: '400px' }}>
          <img
            src={event.image}
            alt={event.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </div>
        <div style={{ flex: '1', padding: '1.5rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '1rem' }}>
            <h2 style={{ color: 'var(--primary-blue)' }}>{event.title}</h2>
            <div style={{ textAlign: 'right' }}>
              <p style={{ color: 'var(--dark-gray)', fontWeight: '500' }}>
                {new Date(event.date).toLocaleDateString()}
              </p>
              <p style={{ color: 'var(--dark-gray)' }}>{event.time}</p>
            </div>
          </div>
          <p style={{ marginBottom: '1rem' }}>{event.description}</p>
          <div style={{ marginBottom: '1rem' }}>
            <p style={{ fontWeight: '500' }}>{event.location}</p>
            <p style={{ color: 'var(--dark-gray)' }}>{event.address}</p>
          </div>
          {isPast && event.highlights && (
            <div>
              <h3 style={{ color: 'var(--primary-blue)', marginBottom: '0.5rem' }}>Event Highlights</h3>
              <ul style={{ paddingLeft: '1.5rem' }}>
                {event.highlights.map((highlight, index) => (
                  <li key={index} style={{ marginBottom: '0.25rem' }}>{highlight}</li>
                ))}
              </ul>
            </div>
          )}
          {!isPast && (
            <button
              className="animated-tab"
              style={{
                marginTop: '1rem',
                backgroundColor: 'var(--primary-blue)',
                color: 'white'
              }}
            >
              Register Now
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="page-container">
      <h1 className="page-title">Events</h1>

      <section className="section">
        {/* Past Events Section */}
        <div style={{ marginBottom: '4rem' }}>
          <h2 style={{ 
            color: 'var(--primary-blue)', 
            marginBottom: '2rem', 
            fontSize: '2rem',
            textAlign: 'center',
            position: 'relative',
            paddingBottom: '0.5rem'
          }}>
            Past Events
          </h2>
          <div className="events-container">
            {events.past.map(event => (
              <EventCard key={event.id} event={event} isPast={true} />
            ))}
          </div>
        </div>

        {/* Upcoming Events Section */}
        <div>
          <h2 style={{ 
            color: 'var(--primary-blue)', 
            marginBottom: '2rem', 
            fontSize: '2rem',
            textAlign: 'center',
            position: 'relative',
            paddingBottom: '0.5rem'
          }}>
            Upcoming Events
          </h2>
          <div className="events-container">
            {events.upcoming.map(event => (
              <EventCard key={event.id} event={event} isPast={false} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Event; 